import React, { useState } from 'react';
import './RAC.css';

const RAC = () => {
  const [currentLoanAmount, setCurrentLoanAmount] = useState('');
  const [currentInterestRate, setCurrentInterestRate] = useState('');
  const [remainingLoanTerm, setRemainingLoanTerm] = useState('');
  const [newInterestRate, setNewInterestRate] = useState('');
  const [newLoanTerm, setNewLoanTerm] = useState('');
  const [monthlySavings, setMonthlySavings] = useState(null);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (value >= 0 || value === '') {
      setter(value);
    }
  };

  const calculateSavings = () => {
    const currentMonthlyPayment =
      (currentLoanAmount * (currentInterestRate / 100) / 12) /
      (1 - Math.pow(1 + (currentInterestRate / 100) / 12, -remainingLoanTerm * 12));
    const newMonthlyPayment =
      (currentLoanAmount * (newInterestRate / 100) / 12) /
      (1 - Math.pow(1 + (newInterestRate / 100) / 12, -newLoanTerm * 12));
    const savings = currentMonthlyPayment - newMonthlyPayment;
    setMonthlySavings(savings.toFixed(2));
  };

  return (
    <div className="calc">
      <h1 className="calculator-title">Refinance Analysis Calculator</h1>
      <div className="calculator-container">
        <div className="calculator-content">
          <div className="input-group">
            <label>Current Loan Amount:</label>
            <input
              type="number"
              value={currentLoanAmount}
              onChange={handleInputChange(setCurrentLoanAmount)}
              placeholder="Enter current loan amount"
            />
          </div>
          <div className="input-group">
            <label>Current Interest Rate (%):</label>
            <input
              type="number"
              value={currentInterestRate}
              onChange={handleInputChange(setCurrentInterestRate)}
              placeholder="Enter current interest rate"
            />
          </div>
          <div className="input-group">
            <label>Remaining Loan Term (years):</label>
            <input
              type="number"
              value={remainingLoanTerm}
              onChange={handleInputChange(setRemainingLoanTerm)}
              placeholder="Enter remaining loan term"
            />
          </div>
          <div className="input-group">
            <label>New Interest Rate (%):</label>
            <input
              type="number"
              value={newInterestRate}
              onChange={handleInputChange(setNewInterestRate)}
              placeholder="Enter new interest rate"
            />
          </div>
          <div className="input-group">
            <label>New Loan Term (years):</label>
            <input
              type="number"
              value={newLoanTerm}
              onChange={handleInputChange(setNewLoanTerm)}
              placeholder="Enter new loan term"
            />
          </div>
          <button className="calculate-button" onClick={calculateSavings}>
            Calculate Savings
          </button>
          {monthlySavings !== null && (
            <div className="savings-result">
              <h2>Estimated Monthly Savings: ${monthlySavings}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RAC;
