import React from 'react';
import './FRM.css'; // Import the CSS file
import armImage from "../image/loans.jpg"; // Import the image
const FRM = () => {
  return (
    <div className="frm-container">
  <div className="arm-image-container">
        <div className="overlayarm"></div>
        <img src={armImage} alt="va" className="arm-image" />
        <div className="overlay-text-arm">FIXED-RATES MORTGAGE</div>
      </div>
      <h1 className="frm-title">Find Stability and Security with Succedo's Fixed-Rate Mortgage (FRM)</h1>
      <h2 className="frm-subtitle">Succedo's FRM offers you:</h2>
      <ol>
        <li>Stable monthly payments throughout the loan term.</li>
        <li>Predictable interest rates for easier budgeting.</li>
        <li>Protection from potential rate hikes in the future.</li>
        <li>Peace of mind knowing your mortgage payments won't fluctuate.</li>
      </ol>
      <a href="https://2584954.my1003app.com/2085367/inquiry"><button className="apply-button">Apply Now</button></a>
    </div>
  );
};

export default FRM;